import React from "react"
import { graphql } from "gatsby"
import tw from 'twin.macro'


// import Bio from "../components/bio"
import Layout from "../components/layout"
import BlogCard from "../components/blog_card"

import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulBlogPost.edges

  const PostWrap = tw.div`
    flex
    flex-row
    flex-wrap
    justify-center
    mx-auto
    `

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title="The Culture Yard Blog" description="We love Chinese culture and language and hope this comes off in our blog. We cover topics ranging from the HSK exams, living in China, idioms, and cultural phenomena." />
      <h1 tw="text-center">The Culture Yard Blog</h1>
      <PostWrap>
      {posts && posts.map(({ node }) => {
        return (
        <BlogCard
          title={node.title}
          image={node.featureImage.fluid}
          image_title={node.featureImage.description}
          link={node.slug} date={node.publishDate}
          description={node.description}
          html= {node.description === null ?  "Description" : node.description.description }
          category={node.category.name}
          category_link={node.category.slug}
          />
        )
      }
    )}
    </PostWrap>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    allContentfulBlogPost(filter: {node_locale: {eq: "en-US"}} sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          category{
            name
            slug
          }
          slug
          title
          featureImage {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          author {
            name
            node_locale
          }
          body {
            body
          }
          publishDate(formatString: "MMMM DD, YYYY")
          description {
            description
          }
          # tags {
          #   name
          # }
        }
      }
    }
    intro:contentfulLayoutCopy(title: {regex: "/Blog > Intro/"}) {
      copy {
        copy
      }
    }
  }
`
